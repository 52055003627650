body {
  margin: 0;
  display: flex;
}

html, body, #root, #root>div {
  width: 100%;
  min-height: 100vh;
  background-color: #F7F7F7;
}

#root {
  width: 100%;
  position: relative;
}